import React, { Fragment, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import classNames from "classnames"

import Container from "../../Layout/Container"

import { ProgramName, Brand } from "../../Elements/Brand"
import { animate } from "../../../services/animations"
import styles from "../utils/staticPages.modules.scss"

const HomeBanner = () => {
  const data = useStaticQuery(graphql`
    {
      stethoscope: file(relativePath: { eq: "stethoscope.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2000
            duotone: { highlight: "#ffffff", shadow: "#3d8b8b" }
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const stethoscope = data.stethoscope.childImageSharp.fluid

  useEffect(() => {
    animate({ target: ".fade-to-right", x: -50 })
  }, [])

  return (
    <Fragment>
      <section className="hero is-medium">
        <div
          className="hero-body"
          style={{
            backgroundImage: `url(${stethoscope.src})`,
            backgroundPosition: "cover",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container isCentered desktop={10} fullhd={8}>
            <div className="container fade-to-right">
              {" "}
              <h2>
                Welcome to the <ProgramName />{" "}
                <br className="is-hidden-mobile" />
                for Nintedanib (Ofev)
              </h2>
              <h5 className="subtitle">
                The <ProgramName /> is a medicine assistance and adherence
                program <br className="is-hidden-mobile" /> for selected{" "}
                <Brand type="company" /> medicines.
              </h5>
              <Link
                to="/financial-assessment/"
                className={classNames(
                  "button mt-1 mr-1 is-primary is-medium",
                  styles["home__buttonIsFullwidth"]
                )}
              >
                Apply for Financial Assistance
              </Link>
              <Link
                to="/epharmacy"
                className={classNames(
                  "button mt-1 is-primary is-medium",
                  styles["home__buttonIsFullwidth"]
                )}
              >
                Order Medicines
              </Link>
              <div className={classNames("is-flex mt-2")}>
                <section>
                  First time in the program?{" "}
                  <Link to="/patient" className="has-text-weight-bold">
                    Enroll here.
                  </Link>
                </section>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </Fragment>
  )
}

export default HomeBanner
