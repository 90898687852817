import React, { Fragment } from "react"
import classNames from "classnames"

import { Link } from "gatsby"
import Container from "layout/Container"
import financialAssessmentRequirements from "../utils/financialAssessmentRequirements.json"

const HomeProgramEligibility = () => {
  return (
    <Container isCentered desktop={10} fullhd={8}>
      <h3 className="my-3 has-text-centered has-text-primary">
        Who is eligible to participate in the program?
      </h3>
      <h5 className="subtitle has-text-centered mb-5">
        You can enroll in the program if you are prescribed any of the medicines
        covered in the program.
      </h5>
      <h3 className="my-3 has-text-centered has-text-primary">
        How do I apply for financial assistance?
      </h3>
      <p className="subtitle">
        Patients who are already enrolled in the program may apply for financial
        assistance. To receive assistance, fill out the financial assessment
        form and submit your proof of income and information on your expenses.
        Proof of income documentation includes:
      </p>
      <article className="message">
        <div className={classNames("message-body collapsible")}>
          <section className="content">
            {financialAssessmentRequirements && (
              <Fragment>
                <ul>
                  {financialAssessmentRequirements.map(value => (
                    <li
                      className="is-size-6"
                      dangerouslySetInnerHTML={{ __html: value }}
                    ></li>
                  ))}
                </ul>
              </Fragment>
            )}
          </section>
        </div>
      </article>
      <p className="subtitle">
        Once you have submitted your complete requirements, our team will verify
        your application to determine the financial assistance that will be
        granted to you. You will receive an SMS indicating the results of our
        financial assessment within 2-5 working days. Once you receive your
        assessment result, you may order your medicines through the website.
      </p>
      <p className="subtitle">
        Have another question? Get answers at our{" "}
        <Link to="/help-center" className="has-text-weight-bold">
          Help Center
        </Link>
        .
      </p>
    </Container>
  )
}

export default HomeProgramEligibility
