import React, { Fragment } from "react"

import HomeBanner from "./HomeBanner"
import HomeHowToParticipate from "./HomeHowToParticipate"
import HomeProgramEligibility from "./HomeProgramEligibility"
import HomeProgramExpectations from "./HomeProgramExpectations"

const Home = () => {
  return (
    <Fragment>
      <HomeBanner />
      <HomeProgramExpectations />
      <HomeHowToParticipate />
      <HomeProgramEligibility />
    </Fragment>
  )
}

export default Home
