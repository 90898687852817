import React from "react"
import Img from "gatsby-image"
import classNames from "classnames"

const HomeMediaContainer = ({ title, description, image, animationClass }) => (
  <div className={classNames("column mb-3-mobile mx-1", animationClass)}>
    <div className={classNames("home__image")}>
      <Img fixed={image} />
    </div>
    <h3 className="mt-1 mb-2-mobile has-text-centered has-text-primary is-size-4">
      {title}
    </h3>
    <p className="mx-3-mobile is-size-5 has-text-centered">{description}</p>
  </div>
)

export default HomeMediaContainer
