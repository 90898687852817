import React from "react"

import Hero from "layout/Hero"
import Container from "layout/Container"
import HomeMediaContainer from "./HomeMediaContainer"

import programExpectations from "../utils/programExpectations.json"
import useHomeImages from "../hooks/useHomeImages"

const HomeProgramExpectations = () => {
  const data = useHomeImages()

  return (
    <Hero size="small">
      <Container isCentered desktop={10} fullhd={8}>
        <h3 className="my-3 has-text-centered has-text-primary">
          What can I expect from the program?
        </h3>
        <div className="columns is-centered mb-3">
          {programExpectations.map(item => (
            <HomeMediaContainer
              title={item?.title}
              description={item?.description}
              image={data[item?.imageName]?.childImageSharp?.fixed}
            />
          ))}
        </div>
      </Container>
    </Hero>
  )
}

export default HomeProgramExpectations
