import React, { useState, Fragment } from "react"
import { navigate } from "gatsby"

import SEO from "layout/SEO"
import Layout from "layout/Layout"
import Home from "../components/StaticPages/Home/Home"
import PageNavigation from "../components/StaticPages/PageNavigation/PageNavigation"

import { isBrowser } from "../services/general"
import {
  GATSBY_ENABLED_SITES,
  GATSBY_SITE_NAVIGATION_HOPE,
  GATSBY_SITE_NAVIGATION_GIOTRIF,
} from "gatsby-env-variables"

const HOPE_VIEW = "HOPE_VIEW"
const GIOTRIF_VIEW = "GIOTRIF_VIEW"

const IndexView = ({ view, setView, setIsHome }) => {
  let siteNavigation = ""

  if (isBrowser()) {
    siteNavigation = JSON.parse(sessionStorage.getItem("site") || "{}")

    if (siteNavigation?.code === GATSBY_SITE_NAVIGATION_HOPE) view = HOPE_VIEW
    else if (siteNavigation?.code === GATSBY_SITE_NAVIGATION_GIOTRIF)
      view = GIOTRIF_VIEW
  }

  if (!GATSBY_ENABLED_SITES.includes("Giotrif")) return <Home />
  else
    switch (view) {
      case GIOTRIF_VIEW:
      case HOPE_VIEW:
      default:
        if (isBrowser()) navigate("/giotrif")
        return <Fragment></Fragment>
    }
}

export default () => {
  const [view, setView] = useState(GIOTRIF_VIEW)
  const [isHome, setIsHome] = useState(true)
  return (
    <Layout isHome={isHome}>
      <SEO title="Home" />
      <IndexView setView={setView} view={view} setIsHome={setIsHome} />
    </Layout>
  )
}
